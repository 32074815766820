// extracted by mini-css-extract-plugin
export var ArtistDescription = "RickProl-module--ArtistDescription--pxa3v";
export var ArtistInfos = "RickProl-module--ArtistInfos--47e5b";
export var ButtonWrapper = "RickProl-module--ButtonWrapper --0-TC-";
export var CardWrapper = "RickProl-module--CardWrapper--PEQVC";
export var CarrouselWrapper2 = "RickProl-module--CarrouselWrapper2--uoIcZ";
export var Citations = "RickProl-module--Citations--6Nt-Q";
export var DescriptionWrapper = "RickProl-module--DescriptionWrapper--5bemk";
export var ImageWrapper = "RickProl-module--ImageWrapper--AUBVe";
export var LinkWrapper = "RickProl-module--LinkWrapper--zdJnI";
export var MobileProtrait = "RickProl-module--MobileProtrait--yPVjY";
export var More = "RickProl-module--More--nYCZm";
export var MoreButton = "RickProl-module--MoreButton--VgLDS";
export var NameWrapper = "RickProl-module--NameWrapper--NjUf0";
export var PdpWrapper = "RickProl-module--PdpWrapper--WW0Hq";
export var PhotosWrapper = "RickProl-module--PhotosWrapper--Cze55";
export var ProfilWrapper = "RickProl-module--ProfilWrapper--KldLp";
export var TitleWrapper = "RickProl-module--TitleWrapper--DtkYP";
export var Wrapper = "RickProl-module--Wrapper--Fg+ya";